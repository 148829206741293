import { Button, Paper, TextField, Typography, Box, Grid, Divider, Stack } from '@mui/material';
import "../theme/custom-less/login.less"
import { useState } from 'react';
import OkIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Error";
import ErrorIcon from "@mui/icons-material/Cancel";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const existsToIcon = (exists) => {

  if (exists) {
    return <OkIcon sx={{ color: "success.main" }} />;
  }

  return <ErrorIcon sx={{ color: "error.main" }} />;
}


export default function CheckInStatusPage() {

  const navigate = useNavigate();
  const { code } = useParams();

  const handleSubmit = () => {
    navigate("/login");
  }

  return (
    <>
      <Typography
        variant="h5"
        sx={{ mt: 4, mb: 2, color: "common.white", textAlign: "center" }}
      >
        Check In Status
      </Typography>
      <Paper elevation={3} sx={{ p: 3 }}>

        <Typography variant='h6' textAlign="center">
          Event Registrant Information
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Grid container spacing={2} sx={{ p: 3 }}>

          <Grid item xs={12} sm={6}>
            <Typography variant='body1'>
              Confirmation Code
            </Typography>
            <Typography variant='body2'>
              {code}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant='body1'>
              # Participants
            </Typography>
            <Typography variant='body2'>
              2
            </Typography>
          </Grid>

          <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

          <Grid item xs={12}>
            <Typography variant='body1' mb={2}>
              Primary Contact
            </Typography>

            <Stack direction="row" spacing={1} alignItems="center">
              <OkIcon sx={{ color: "success.main" }} />
              <Typography variant='body2'>
                <strong>First Name:</strong> John
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <OkIcon sx={{ color: "success.main" }} />
              <Typography variant='body2'>
                <strong>Last Name:</strong> Doe
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <OkIcon sx={{ color: "success.main" }} />
              <Typography variant='body2'>
                <strong>Email Address:</strong> jack@juxtmarketing.com
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <OkIcon sx={{ color: "success.main" }} />
              <Typography variant='body2'>
                <strong>Phone Number:</strong> (601) 555-5555
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <OkIcon sx={{ color: "success.main" }} />
              <Typography variant='body2'>
                <strong>Street Address:</strong> 1234 Main Street
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <OkIcon sx={{ color: "success.main" }} />
              <Typography variant='body2'>
                <strong>City:</strong> Atlanta
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <OkIcon sx={{ color: "success.main" }} />
              <Typography variant='body2'>
                <strong>State:</strong> Georgia
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <OkIcon sx={{ color: "success.main" }} />
              <Typography variant='body2'>
                <strong>Zip Code:</strong> 44444
              </Typography>
            </Stack>

          </Grid>

          <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

          <Grid item xs={12}>

            <Stack direction="row" spacing={1} alignItems="center">
              <OkIcon sx={{ color: "success.main" }} />
              <Typography variant='body2'>
                Paid in Full
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <OkIcon sx={{ color: "success.main" }} />
              <Typography variant='body2'>
                Permission Slip(s)
              </Typography>
            </Stack>

          </Grid>

          <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Grid>

        </Grid>

      </Paper>
    </>
  );
}