import { Button, Container, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { EventsTable, CreateEventModal, DeleteEventModal } from "../sections/dashboard/events";
import { useNavigate } from "react-router-dom";
import useEvents from "../hooks/useEvents";
import { useState } from "react";
import { useConfig } from "../context/ConfigContext";
import { useAuth } from "../context/AuthContext";

export default function EventsPage() {

  const { events, status, error } = useEvents();
  const { apiPath } = useConfig();
  const { authHeaders, isAdmin } = useAuth();
  const navigate = useNavigate();

  console.log(isAdmin)


  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState({
    showConfirm: false,
    event: null
  })

  const handleDeleteEvent = (event) => {

    fetch(`${apiPath}/events/delete`, {
      method: "POST",
      headers: authHeaders,
      body: JSON.stringify({ Multi_Event_ID: event.Multi_Event_ID })
    }).then(res => {
      if (res.ok) return res.json();
      console.error("Error deleting event: ", res);
    }).then(data => {
      navigate(0);
    });

    setDeleteEvent({ showConfirm: false, event: null })
  }

  const handleCreateEvent = (id) => {

    fetch(`${apiPath}/events/create`, {
      method: "POST",
      headers: authHeaders,
      body: JSON.stringify({ Event_ID: id })
    }).then(res => {
      if (res.ok) return res.json();
      console.error("Error creating event: ", res);
    }).then(data => {
      navigate(`./${data.Multi_Event_ID}/edit`);
    });

    setShowCreateEventModal(false);
  }

  return (
    <Container>
      <Stack sx={{ py: 4 }} direction="row">

        <Typography variant="h4">
          {isAdmin ? `Events` : `My Events`}
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        {isAdmin &&
          <Button
            variant="contained"
            color="primary"
            onClick={() => { setShowCreateEventModal(true) }}>
            Create Event
          </Button>
        }

      </Stack>

      <Paper sx={{ py: 2, px: 5 }}>

        <EventsTable
          eventData={events}
          onEventRowClick={(id) => { navigate(`./${id}`) }}
          onEventView={(id) => { navigate(`/events/${id}`) }}
          onEventEdit={(id) => { navigate(`./${id}/edit`) }}
          onEventDelete={(event) => {
            setDeleteEvent({ showConfirm: true, event: event })
          }}
          isAdmin={isAdmin}
        />

      </Paper>

      <DeleteEventModal
        event={deleteEvent.event}
        open={deleteEvent.showConfirm}
        onConfirm={() => { handleDeleteEvent(deleteEvent.event) }}
        onCancel={() => { setDeleteEvent({ showConfirm: false, event: null }) }}
      />

      <CreateEventModal
        open={showCreateEventModal}
        onCreateEvent={handleCreateEvent}
        onClose={() => { setShowCreateEventModal(false) }}
      />

    </Container>
  );
}