import { Button, Divider, Grid, Typography, Radio as MuiRadio, FormControlLabel } from "@mui/material";
import { useForm } from "react-hook-form";
import { Input, File, Radio } from "../form-fields";

const REQUIRED_RULE = {
  required: {
    value: true,
    message: "This field is required"
  }
};

const PHONE_RULE = {
  pattern: {
    value: /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
    message: "Not a valid phone number."
  }
};

const ZIP_RULE = {
  pattern: {
    value: /^\d{5}(?:[-\s]\d{4})?$/,
    message: "Not a valid zip code."
  }
}

const EMAIL_RULE = {
  pattern: {
    value: /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.([a-z]+)|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/,
    message: "Not a valid email address."
  }
}

export default function BulkRegistrationForm({ values, onSubmit, submitBtnRef }) {

  const { handleSubmit, control } = useForm({
    mode: "onChange",
    defaultValues: {
      Leader_First_Name: "",
      Leader_Last_Name: "",
      Leader_Email_Address: "",
      Leader_Phone_Number: "",
      Leader_Mailing_Address: "",
      Leader_City: "",
      Leader_State: "",
      Leader_Zip_Code: "",
      Number_Of_Participants: "",
      Payment_Type: "",
    },
    values
  });

  const onSubmitCallback = (data) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitCallback)}>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center">
            Information
          </Typography>
        </Grid>


        <Grid item xs={12} sm={6}>
          <Input
            fullWidth
            control={control}
            name="Leader_First_Name"
            rules={{ ...REQUIRED_RULE }}
            label="Group Leader First Name"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Input
            fullWidth
            control={control}
            name="Leader_Last_Name"
            rules={{ ...REQUIRED_RULE }}
            label="Group Leader Last Name"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Input
            fullWidth
            control={control}
            name="Leader_Email_Address"
            rules={{ ...REQUIRED_RULE, ...EMAIL_RULE }}
            label="E-mail Address"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Input
            fullWidth
            control={control}
            name="Leader_Phone_Number"
            rules={{ ...REQUIRED_RULE, ...PHONE_RULE }}
            label="Phone Number"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Input
            fullWidth
            control={control}
            name="Leader_Mailing_Address"
            rules={{ ...REQUIRED_RULE }}
            label="Mailing Address"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Input
            fullWidth
            control={control}
            name="Leader_City"
            rules={{ ...REQUIRED_RULE }}
            label="City"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Input
            fullWidth
            control={control}
            name="Leader_State"
            rules={{ ...REQUIRED_RULE }}
            label="State"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Input
            fullWidth
            control={control}
            name="Leader_Zip_Code"
            rules={{ ...REQUIRED_RULE, ...ZIP_RULE }}
            label="Zip Code"
          />
        </Grid>

        <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center">
            Participants
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Input
            fullWidth
            control={control}
            name="Number_Of_Participants"
            rules={{ ...REQUIRED_RULE }}
            label="Number of Participants"
            helperText="*Individual participant information can be added at a later date"
          />
        </Grid>

        <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center">
            Payment
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Radio 
            control={control} 
            name="Payment_Type"
            rules={{ ...REQUIRED_RULE }}
          >

            <Grid item xs={12} md={6}>
              <FormControlLabel value="full-via-card" control={<MuiRadio />} label="Pay in Full via Credit Card" />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel value="full-via-check" control={<MuiRadio />} label="Pay in Full via Check" />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel value="half-via-card" control={<MuiRadio />} label="50% Partial Payment via Credit Card" />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControlLabel value="half-via-check" control={<MuiRadio />} label="50% Partial Payment via Check" />
            </Grid>

          </Radio>
        </Grid>

        <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center">
            Permission Slip
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
          >
            Download Permission Slip Form
          </Button>
        </Grid>

        <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

        <Grid item xs={12}>
          <Button
            // sx={{ display: "none" }}
            fullWidth
            ref={submitBtnRef}
            variant="contained"
            color="primary"
            type="submit">
            Submit
          </Button>
        </Grid>

      </Grid>

    </form>
  );
}