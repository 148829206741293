import { Button, Container, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { EditRegistrationForm } from "../components/registration-form";
import { useParams } from "react-router-dom";
import { useRegistrationParticipants, useRegistration } from "../hooks";
import { ParticipantsTable, DeleteParticipantModal } from "../sections/dashboard/registration-detail";
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { useRef, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import { useAuth } from "../context/AuthContext";

export default function EditRegistrationPage() {

  const { id } = useParams();
  const {
    registration,
    status: registrationStatus,
    error: registrationError
  } = useRegistration(id);

  const {
    participants,
    status: participantsStatus,
    error: participantsError
  } = useRegistrationParticipants(id);

  const { apiPath } = useConfig();
  const { authHeaders } = useAuth();
  const navigate = useNavigate();
  const submitBtnRef = useRef(null);

  const [deleteParticipant, setDeleteParticipant] = useState({
    showConfirm: true,
    participant: null
  });

  const triggerSubmit = () => {
    submitBtnRef.current.click();
  };

  const handleSubmit = (data) => {

    fetch(`${apiPath}/registrations/update`, {
      method: "POST",
      headers: authHeaders,
      body: JSON.stringify(data)
    }).then(res => {
      if (res.ok) return res.json();
      console.error("Error updating registration: ", res);
    }).then(data => {
      navigate(-1);
    });
  };

  const handleDeleteParticipant = () => {

    fetch(`${apiPath}/participants/delete`, {
      method: "POST",
      headers: authHeaders,
      body: JSON.stringify({ Multi_Event_Participant_ID: deleteParticipant.participant.Multi_Event_Participant_ID })
    }).then(res => {
      if (res.ok) return res.json();
      console.error("Error deleting participant: ", res);
    }).then(data => {
      setDeleteParticipant({ showConfirm: false, participant: null });
      navigate(0);
    });
  };

  return (
    <Container>
      <Stack sx={{ py: 4 }} direction="row">

        <Typography variant="h4">
          Edit Registration
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        <Button
          variant="contained"
          color="primary"
          onClick={triggerSubmit}
        >
          Save
        </Button>

      </Stack>

      <Paper sx={{ py: 2, px: 5, mb: 4 }}>

        <EditRegistrationForm
          loading={!registration}
          values={registration}
          submitBtnRef={submitBtnRef}
          onSubmit={handleSubmit}
        />

      </Paper>

      <Paper sx={{ py: 2, px: 5 }}>

        <Stack direction="row">

          <Box sx={{ flexGrow: 1 }} />

          <Button
            variant="contained"
            color="primary"
            endIcon={<AddIcon />}
            onClick={() => navigate(`../registrations/${id}/create-participant`)}
          >
            New Participant
          </Button>

        </Stack>

        <ParticipantsTable
          participants={participants}
          onParticipantEdit={(id) => navigate(`../participants/${id}/edit`)}
          onParticipantDelete={
            (participant) =>
              setDeleteParticipant({ showConfirm: true, participant: participant })
          }
        />

        <DeleteParticipantModal
          open={deleteParticipant.showConfirm}
          participant={deleteParticipant.participant}
          onCancel={() => setDeleteParticipant({ showConfirm: false, participant: null })}
          onConfirm={handleDeleteParticipant}
        />

      </Paper>

    </Container>
  );
}