import { Button, Divider, Grid, Skeleton, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { Input, File } from "../form-fields";

const REQUIRED_RULE = {
  required: {
    value: true,
    message: "This field is required"
  }
};

const PHONE_RULE = {
  pattern: {
    value: /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
    message: "Not a valid phone number."
  }
};

const ZIP_RULE = {
  pattern: {
    value: /^\d{5}(?:[-\s]\d{4})?$/,
    message: "Not a valid zip code."
  }
}

const EMAIL_RULE = {
  pattern: {
    value: /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.([a-z]+)|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/,
    message: "Not a valid email address."
  }
}

export default function ParticipantForm({ values, onSubmit, submitBtnRef, loading }) {

  const { handleSubmit, control } = useForm({
    mode: "onChange",
    defaultValues: {
      Multi_Event_Participant_First_Name: "",
      Multi_Event_Participant_Last_Name: "",
      Multi_Event_Participant_Email_Address: "",
      Multi_Event_Participant_Phone_Number: "",
      Multi_Event_Participant_Mailing_Address: "",
      Multi_Event_Participant_State: "",
      Multi_Event_Participant_City: "",
      Multi_Event_Particpant_Zip_Code: "",
      Multi_Event_Participant_Emerg_Contact_First_Name: "",
      Multi_Event_Participant_Emerg_Contact_Last_Name: "",
      Multi_Event_Participant_Emerg_Contact_Relationship: "",
      Multi_Event_Participant_Emerg_Contact_Phone_Number: "",
      Permission_Slip: null
    },
    values
  });

  const onSubmitCallback = (data) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitCallback)}>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center">
            Information
          </Typography>
        </Grid>


        <Grid item xs={12} sm={6}>
          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Participant_First_Name"
              rules={{ ...REQUIRED_RULE }}
              label="First Name"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Participant_Last_Name"
              rules={{ ...REQUIRED_RULE }}
              label="Last Name"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Participant_Email_Address"
              rules={{ ...REQUIRED_RULE, ...EMAIL_RULE }}
              label="E-mail Address"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Participant_Phone_Number"
              rules={{ ...REQUIRED_RULE, ...PHONE_RULE }}
              label="Phone Number"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Participant_Mailing_Address"
              rules={{ ...REQUIRED_RULE }}
              label="Mailing Address"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Participant_City"
              rules={{ ...REQUIRED_RULE }}
              label="City"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Participant_State"
              rules={{ ...REQUIRED_RULE }}
              label="State"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Particpant_Zip_Code"
              rules={{ ...REQUIRED_RULE, ...ZIP_RULE }}
              label="Zip Code"
            />
          )}
        </Grid>

        <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center">
            Emergency Contact
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Participant_Emerg_Contact_First_Name"
              rules={{ ...REQUIRED_RULE }}
              label="First Name"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Participant_Emerg_Contact_Last_Name"
              rules={{ ...REQUIRED_RULE }}
              label="Last Name"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Participant_Emerg_Contact_Relationship"
              rules={{ ...REQUIRED_RULE }}
              label="Relationship"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Participant_Emerg_Contact_Phone_Number"
              rules={{ ...REQUIRED_RULE, ...PHONE_RULE }}
              label="Phone Number"
            />
          )}
        </Grid>

        <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center">
            Permission Slip
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Button
            fullWidth
            variant="outlined"
          >
            Download Permission Slip Form
          </Button>
        </Grid>

        <Grid item xs={12} md={6}>
          <File
            control={control}
            name="Permission_Slip"
            rules={{ ...REQUIRED_RULE }}
          />
        </Grid>

        <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

        <Grid item xs={12}>
          <Button
            sx={{ display: "none" }}
            fullWidth
            ref={submitBtnRef}
            variant="contained"
            color="primary"
            type="submit">
            Submit
          </Button>
        </Grid>

      </Grid>

    </form>
  );
}