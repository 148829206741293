import { Button, Divider, Typography, Stack, Box, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fDateTime } from "../../../utils/formatTime";
import { fDollars } from "../../../utils/formatNumber";

export default function RegistrationDetails({ event }) {

  const navigate = useNavigate();

  return (
    <Box>

      <Stack spacing={1}>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => navigate(`/register/${event.Multi_Event_ID}`)}
        >
          Single Registration
        </Button>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => navigate(`/register/${event.Multi_Event_ID}/bulk`)}
        >
          Bulk Registration
        </Button>

      </Stack>

      <Divider sx={{ my: 2 }} />

      {/* <Stack spacing={2} direction="row">

        <Box>
          <Typography variant="body1" gutterBottom>
            Event Location
          </Typography>
          <Typography variant="body2">
            lorem ipsum dolor sit amet consectetur adipisicing elit.
          </Typography>
        </Box>

        <Box>
          <Typography variant="body1" gutterBottom>
            Event Date
          </Typography>
          <Typography variant="body2">
            lorem ipsum dolor sit amet consectetur adipisicing elit.
          </Typography>
        </Box>

      </Stack> */}

      <Typography variant="body1" gutterBottom>
        Date(s) and Time(s)
      </Typography>

      <Typography variant="body2">
        <strong>Start:</strong> {fDateTime(event.Multi_Event_Start_Date)} at {fDateTime(event.Multi_Event_Start_Date, 't')}
      </Typography>

      <Typography variant="body2">
        <strong>End:</strong> {fDateTime(event.Multi_Event_End_Date)} at {fDateTime(event.Multi_Event_End_Date, 't')}
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="body1">
        Cost
      </Typography>

      <Typography variant="body2">
        {fDollars(event.Multi_Event_Cost)}
      </Typography>


      <Divider sx={{ my: 2 }} />

      <Typography variant="body1">
        Website
      </Typography>

      <Typography variant="body2">
        <Link 
          href="https://www.pureheart.org/"
          target="_blank"
          underline="none"
          sx={{ cursor: 'pointer' }}
        >
          https://www.pureheart.org/
        </Link>
      </Typography>

      <Divider sx={{ my: 2 }} />

    </Box>
  )
}