import {
  styled,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,

} from "@mui/material";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: theme.palette.background.modal,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    minWidth: 400,
  },
  "& .MuiDialogTitle-root": {
    textAlign: "center",
  },
}));

export default function DeleteEventModal({ event, open, onConfirm, onCancel }) {

  if (!event) return null;

  return (
    <StyledDialog open={open} onClose={onCancel}>
      <DialogTitle>Delete Event</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete the event {event?.Multi_Event_Title}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          autoFocus
          onClick={onConfirm}
          variant="contained"
          color="error"
        >
          Yes
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}