import { Button, Container, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useParams } from "react-router-dom";
import { useParticipant } from "../hooks";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useConfig } from "../context/ConfigContext";
import { useAuth } from "../context/AuthContext";
import { ParticipantForm } from "../components/participant-form";

export default function EditParticipantPage() {

  const { id } = useParams();
  const { participant, status, error } = useParticipant(id);

  const { apiPath } = useConfig();
  const { authHeaders } = useAuth();
  const navigate = useNavigate();
  const submitBtnRef = useRef(null);

  const triggerSubmit = () => {
    submitBtnRef.current.click();
  };

  const handleSubmit = (data) => {

    if (data.Permission_Slip.name === participant.Permission_Slip_URL) {
      delete data.Permission_Slip;
    }

    const formData = new FormData();

    formData.append("Multi_Event_Participant_ID", id);
    for (let key in data) {
      formData.append(key, data[key]);
    }

    fetch(`${apiPath}/participants/update`, {
      method: "POST",
      headers: authHeaders,
      body: formData
    }).then(res => {
      if (res.ok) return res.json();
      console.error("Error updating registration: ", res);
    }).then(data => {
      navigate(-1);
    });
  };

  const permSlipPlaceholder = {};
  if (participant?.Permission_Slip_URL) {
    const dummyFile = new File([], participant.Permission_Slip_URL);
    permSlipPlaceholder.Permission_Slip = dummyFile;
  }

  return (
    <Container>
      <Stack sx={{ py: 4 }} direction="row">

        <Typography variant="h4">
          Edit Participant
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        <Button
          variant="contained"
          color="primary"
          onClick={triggerSubmit}
        >
          Save
        </Button>

      </Stack>

      <Paper sx={{ py: 2, px: 5 }}>

        <ParticipantForm
          values={{
            ...participant,
            ...permSlipPlaceholder
          }}
          submitBtnRef={submitBtnRef}
          onSubmit={handleSubmit}
          loading={!participant}
        />

      </Paper>


    </Container>
  );
}