import { Button, Container, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

export default function RegistrationDetailPage() {


  return (
    <Container>
      <Stack sx={{ py: 4 }} direction="row">

        <Typography variant="h4">
          Registration Details
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        <Button variant="contained" color="primary">
          Primary
        </Button>

      </Stack>

      <Paper sx={{ py: 2, px: 5 }}>

      </Paper>

    </Container>
  );
}