import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Skeleton,
  styled,
  Typography,
  Box,
  Stack
} from "@mui/material";
import FilledSeats from "./FilledSeats";
import SingleIcon from '@mui/icons-material/AccountCircleOutlined';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import OkIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Error";
import RegistrationActions from "./RegistrationActions";
import { useNavigate } from "react-router-dom";

const StyledGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  // justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

const statusToIcon = (status) => {
  switch (status) {
    case "OK":
      return <OkIcon sx={{ color: "success.main" }} />;
    case "NOT_OK":
      return <ErrorIcon sx={{ color: "error.main" }} />;
    case "NEEDS_ATTENTION":
      return <WarningIcon sx={{ color: "warning.main" }} />;
    default:
      return <OkIcon sx={{ color: "success.main" }} />;
  }
};


export default function RegistrationsAccordion({ registrations, onRegistrationView, onRegistrationEdit, onRegistrationDelete }) {

  const navigate = useNavigate();

  return (
    <>
      {registrations && registrations.length === 0 && (
        <Box sx={{ p: 2, textAlign: "center", }}>
          <Typography variant="body2" color="text.secondary">
            No registrations found.
          </Typography>
        </Box>
      )}
      {!registrations && (
        Array.from(new Array(5)).map((_, index) => (
          <Accordion key={index}>
            <AccordionSummary>
              <Skeleton variant="rectangular" width="100%" height={40} />
            </AccordionSummary>
          </Accordion>
        ))
      )}
      {registrations && registrations.map((registration) => (

        <Accordion key={registration.Multi_Event_Registration_ID}>

          <AccordionSummary>

            <Grid container spacing={2} pb={2}>

              <StyledGridItem item xs={12} sm={4} md={3}>
                <Stack direction="row" spacing={1}>
                  {registration.Is_Bulk_Registration
                    ? <GroupIcon color="primary" /> : <SingleIcon color="secondary" />}
                  <Typography variant="body1">
                    {`${(registration.Contact.Nickname || registration.Contact.First_Name)} ${registration.Contact.Last_Name}`}
                  </Typography>
                </Stack>
              </StyledGridItem>

              <StyledGridItem item xs={12} sm={3} md={2}>
                <Stack direction="row" spacing={1}>
                  {statusToIcon(registration.Payment_Status)}
                  <Typography variant="body1">Payment</Typography>
                </Stack>
              </StyledGridItem>

              <StyledGridItem item xs={12} sm={5} md={2}>
                <Stack direction="row" spacing={1}>
                  {statusToIcon(registration.Permission_Slips_Status)}
                  <Typography variant="body1">Permission</Typography>
                </Stack>
              </StyledGridItem>

              <StyledGridItem item xs={12} sm={8} md={4}>
                <Stack direction="row" spacing={3} alignItems="flex-end">
                  <Typography variant="body1">Seats</Typography>
                  <FilledSeats
                    totalSeats={registration.Multi_Event_Number_Of_Participants_Reserved}
                    filledSeats={registration.Number_Of_Seats_Filled}
                  />
                </Stack>
              </StyledGridItem>

              <StyledGridItem item xs={12} sm={4} md={1}>

                <RegistrationActions
                  showView={false}
                  // onView={(e) => { e.stopPropagation(); onRegistrationView(registration.Multi_Event_Registration_ID) }}
                  onEdit={(e) => { e.stopPropagation(); onRegistrationEdit(registration.Multi_Event_Registration_ID) }}
                  onDelete={(e) => { e.stopPropagation(); onRegistrationDelete(registration) }}
                />

              </StyledGridItem>

            </Grid>

          </AccordionSummary>

          <AccordionDetails>

            <Grid container spacing={2} pb={2}>

              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1">E-mail Address</Typography>
                <Typography variant="body2">{registration.Contact.Email_Address || 'N/A'}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1">Phone Number</Typography>
                <Typography variant="body2">{registration.Contact.Mobile_Phone || 'N/A'}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="body1">Address</Typography>
                <Typography variant="body2">{registration.Contact?.Address?.Address_Line_1 || 'N/A'}</Typography>
                <Typography variant="body2">
                  {`${registration.Contact?.Address?.City || 'N/A'}, ${registration.Contact?.Address?.State || 'N/A'} ${registration.Contact?.Address?.Postal_Code || 'N/A'}`}
                </Typography>
              </Grid>

            </Grid>

          </AccordionDetails>

        </Accordion>
      ))}
    </>
  )


}