import {
  styled,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,


} from "@mui/material";
import { useState } from "react";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: theme.palette.background.modal,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    minWidth: 400,
  },
  "& .MuiDialogTitle-root": {
    textAlign: "center",
  },
}));

export default function CreateEventModal({ open, onCreateEvent, onClose }) {

  const [eventId, setEventId] = useState("");

  return (
    <StyledDialog open={open} onClose={onClose}>

      <DialogTitle>Create Event</DialogTitle>

      <DialogContent>

        <TextField
          label="Event ID"
          fullWidth
          sx={{ mt: 1 }}
          value={eventId}
          onChange={(e) => { setEventId(e.target.value) }}
        />

      </DialogContent>

      <DialogActions>

        <Button
          onClick={() => onCreateEvent(eventId)}
          variant="contained"
        >
          Create
        </Button>

      </DialogActions>

    </StyledDialog>
  );
}