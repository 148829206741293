import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useRef } from 'react';
import "../theme/custom-less/login.less"
import Logo from '../components/logo';
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: '#2c3852',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {

  const altauthContainerRef = useRef(null);
  const { loggedIn, initialized } = useAuth();

  useEffect(() => {

    if (window.altauth && altauthContainerRef.current) {
      window.altauth.init(
        altauthContainerRef.current
      );
    }
  }, [window.altauth, altauthContainerRef]);


  if (initialized && loggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <StyledRoot>
      <Main>
        <Container sx={{ mt: 10 }} maxWidth="sm">
          <Logo disabledLink sx={{ width: 150, mx: "auto", mb: 5 }} />
          <div className='login-view' ref={altauthContainerRef} />
        </Container>
      </Main>
    </StyledRoot>
  );
}