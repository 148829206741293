import { useState } from 'react';
import { Typography, Stack, MenuItem, Avatar, Popover, Box } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAuth } from '../../../context/AuthContext';

export default function AccountPopover() {

  const [open, setOpen] = useState(null);
  const { user, logout, isAdmin } = useAuth();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        onClick={handleOpen}
        gap={1}
        sx={{ cursor: 'pointer' }}
      >
        <Typography variant="body1" sx={{ mr: 1 }} display={{ xs: "none", sm: "block" }} >
          {(user.Contact.Nickname || user.Contact.First_Name) + ' ' + user.Contact.Last_Name}
        </Typography>
        <Avatar
          src={user.Contact.Thumbnail_URL}
          alt="photoURL"
          sx={{ width: 32, height: 32 }}
        />
        <ArrowDropDownIcon />
      </Stack>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}>
        <Typography
          variant="body2"
          textAlign="center"
          sx={{ mt: 2 }}
          fontWeight={700}>
          {isAdmin ? `Admin User` : `Standard User`}
        </Typography>
        <MenuItem onClick={logout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
