import { Box, Button, Container, Paper, Stack, Typography } from '@mui/material'
import { EventForm } from '../components/event-form';

export default function CreateEventPage() {

  return (
    <Container>
      
      <Stack sx={{ py: 4 }} direction="row">

        <Typography variant="h4">
          Create Event
        </Typography>

        <Box sx={{ flexGrow: 1 }} />
        
        <Stack direction="row" spacing={1}>

          <Button 
            variant="contained" 
            color="error"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>

          <Button 
            variant="contained" 
            color="primary"
            onClick={() => navigate(-1)}
          >
            Save
          </Button>

        </Stack>

      </Stack>

      <Paper sx={{ py: 2, px: 5 }}>

        <EventForm />

      </Paper>

    </Container>
  );
}