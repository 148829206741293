import { ListItemText, ListItemButton } from "@mui/material";
import { Stack } from "@mui/system";
import { NavLink as RouterLink } from 'react-router-dom';


export default function Navigation({ }) {

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        ml: 4
      }}
    >
      <ListItemButton component={RouterLink} to="./events">
        <ListItemText primary="All Events" />
      </ListItemButton>
      {/* <ListItemButton component={RouterLink} to="./create-event">
        <ListItemText primary="Create Event" />
      </ListItemButton> */}

    </Stack>
  );
}