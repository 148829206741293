import { Container, Stack, Button, Paper, Typography, Box } from "@mui/material";
import { SingleRegistrationForm } from "../components/registration-form";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useConfig } from "../context/ConfigContext";
import { useNavigate } from "react-router-dom";


export default function SingleRegistrationPage() {

  const { id } = useParams();
  const { apiPath } = useConfig();
  const { authHeaders } = useAuth();
  const navigate = useNavigate();

  const submitBtnRef = useRef(null);

  const triggerSubmit = () => {
    submitBtnRef.current.click();
  };

  const handleSubmit = (data) => {

    const formData = new FormData();

    formData.append("Multi_Event_ID", id);
    for (let key in data) {
      formData.append(key, data[key]);
    }

    console.log("Submitting data: ", formData);

    fetch(`${apiPath}/registrations/create`, {
      method: "POST",
      headers: authHeaders,
      body: formData
    }).then(res => {
      if (res.ok) return res.json();
      console.error("Error creating registration: ", res);
    }).then(data => {
      console.log("Registration created: ", data);
      navigate("./thankyou");
    });

  }

  return (

    <Container>
      <Stack sx={{ py: 4 }} direction="row">

        <Typography variant="h4">
          Single Participant Event Registration
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        <Button 
          variant="contained" 
          color="primary"
          onClick={triggerSubmit}>
          Submit
        </Button>

      </Stack>

      <Paper sx={{ py: 2, px: 5 }}>

        <SingleRegistrationForm 
          onSubmit={handleSubmit}
          submitBtnRef={submitBtnRef}
        />

      </Paper>

    </Container>
  );
}