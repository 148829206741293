import ViewIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Stack } from '@mui/material';

export default function RegistrationActions(
  {
    showView = true,
    showEdit = true,
    showDelete = true,
    onView = () => { },
    onEdit = () => { },
    onDelete = () => { },
  }
) {
  return (

    <Stack direction="row" spacing={0}>

      {showView &&
        <IconButton size="small" onClick={onView}>
          <ViewIcon />
        </IconButton>}

      {showEdit &&
        <IconButton size="small" onClick={onEdit}>
          <EditIcon />
        </IconButton>}

      {showDelete &&
        <IconButton size="small" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>}

    </Stack>

  );
}