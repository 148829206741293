import { BrowserRouter } from 'react-router-dom';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';

import Router from './routes';
import ThemeProvider from './theme';
import { AuthProvider } from './context/AuthContext';
import { ConfigProvider } from './context/ConfigContext';

export default function App() {
  return (
    <ConfigProvider>
      <AuthProvider>
        <BrowserRouter>
          <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <Router />
            </LocalizationProvider>
          </ThemeProvider>
        </BrowserRouter>
      </AuthProvider>
    </ConfigProvider>
  );
}