import { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useRef } from "react";

export default function FilledSeats({ totalSeats, filledSeats }) {

  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    setContainerWidth(containerRef.current.offsetWidth);
  }, []);

  const pctFilled = filledSeats / totalSeats * 100;
  const offsetInPx = containerWidth * (pctFilled / 100);

  const atEnd = offsetInPx > (containerWidth - 25);
  const atStart = offsetInPx < 15;

  const showFilledSeatsText = !atEnd;
  const filledSeatsOffset = offsetInPx - (atStart ? 0 : 10)
  const totalSeatsText = atEnd ? `${filledSeats} / ${totalSeats}` : totalSeats;
  const zeroSeatsText = atStart ? '' : 0;

  const countsFontSize = 12;

  return (
    <Box ref={containerRef}>

      <Stack direction="row">

        <Typography
          fontSize={countsFontSize}>
          {zeroSeatsText}
        </Typography>

        {showFilledSeatsText &&
          <Typography
            fontSize={countsFontSize}
            marginLeft={`${filledSeatsOffset}px`}>
            {filledSeats}
          </Typography>
        }

        <Box flexGrow={1} />
        <Typography
          fontSize={countsFontSize}>
          {totalSeatsText}
        </Typography>

      </Stack>

      <Box
        sx={{
          backgroundColor: '#2C3852',
          minWidth: '200px',
          height: '20px',
          display: 'flex',
        }}>
        <Box
          sx={{
            backgroundColor: '#758199',
            height: '100%',
            width: `${pctFilled}%`,
          }} />
      </Box>
      
    </Box>
  );
}