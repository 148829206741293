import { styled } from '@mui/material/styles';
import { AppBar, Grid, Skeleton, Toolbar } from '@mui/material';
import Logo from '../components/logo/Logo';
import { Button, Container, Paper, Typography, Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { EventDetails, RegistrationDetails } from '../sections/landing/event-landing';
import { useParams } from 'react-router-dom';
import { useEvent, usePublicEvent } from '../hooks';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(10),
}));

const EventImagePaper = styled(Paper)(({ theme }) => ({
  height: 240,
  backgroundColor: theme.palette.grey[700],
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

// ----------------------------------------------------------------------

export default function EventsPage() {

  const navigate = useNavigate();
  const { id } = useParams();
  const { event } = usePublicEvent(id);

  return (
    <StyledRoot>
      <AppBar sx={{ backgroundColor: 'primary.dark' }}>
        <Toolbar>
          <Logo />
          <Box sx={{ flexGrow: 1 }} />
          <Button
            color="inherit"
            onClick={() => navigate('/login')}
          >
            Login
          </Button>
        </Toolbar>
      </AppBar>
      <Main>
        <Container>

          <Grid container spacing={3}>

            <Grid item xs={12}>

              {!event &&
                <Skeleton variant="rectangular" width="100%" height={240} />
              }

              {event && event.Image_URL &&
                <EventImagePaper
                  sx={{
                    backgroundImage: `url(${event.Image_URL})`,
                  }}
                />
              }

            </Grid>

            <Grid item xs={12} md={7}>

              {!event &&
                <Skeleton variant="rectangular" width="100%" height={400} />
              }

              {event &&
                <Paper sx={{ py: 2, px: 5, minHeight: "100%" }} >

                  <EventDetails event={event} />

                </Paper>
              }

            </Grid>

            <Grid item xs={12} md={5}>

              {!event &&
                <Skeleton variant="rectangular" width="100%" height={400} />
              }

              {event &&
                <Paper sx={{ py: 2, px: 5 }}>

                  <RegistrationDetails event={event} />

                </Paper>
              }

            </Grid>

          </Grid>

        </Container>

      </Main>

    </StyledRoot>

  );
}