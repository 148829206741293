import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import EventsPage from './pages/EventsPage';
import CreateEventPage from './pages/CreateEventPage';
import DemoPage from './pages/DemoPage';
import EventDetailPage from './pages/EventDetailPage';
import SingleRegistrationPage from './pages/SingleRegistrationPage';
import BulkRegistrationPage from './pages/BulkRegistrationPage';
import EditEventPage from './pages/EditEventPage';
import LoginPage from './pages/LoginPage';
import { useAuth } from './context/AuthContext';
import EditRegistrationPage from './pages/EditRegistrationPage';
import RegistrationDetailPage from './pages/RegistrationDetailPage';
import CreateParticipantPage from './pages/CreateParticipantPage';
import EventLandingPage from './pages/EventLandingPage';
import ThankYouPage from './pages/ThankYouPage';
import EditParticipantPage from './pages/EditParticipantPage';
import CheckInPage from './pages/CheckInPage';
import CheckInLayout from './layouts/checkin/CheckInLayout';
import CheckInStatusPage from './pages/CheckInStatusPage';

// ----------------------------------------------------------------------

const ProtectedRoute = ({ element }) => {
  const { loggedIn, initialized } = useAuth();

  if (initialized && !loggedIn) {
    return <Navigate to="/login" />;
  }

  if (!initialized) {
    return null;
  }

  return element;
};

export default function Router() {
  const routes = useRoutes([
    { path: '/events/:id', element: <EventLandingPage /> },
    {
      path: '/dashboard',
      element: <ProtectedRoute element={<DashboardLayout />} />,
      children: [
        { element: <Navigate to="/dashboard/events" />, index: true },
        { path: 'events', element: <EventsPage /> },
        { path: 'events/:id', element: <EventDetailPage /> },
        { path: 'events/:id/edit', element: <EditEventPage /> },
        { path: 'create-event', element: <CreateEventPage /> },
        { path: 'registrations/:id', element: <RegistrationDetailPage /> },
        { path: 'registrations/:id/edit', element: <EditRegistrationPage /> },
        { path: 'registrations/:id/create-participant', element: <CreateParticipantPage /> },
        { path: 'participants/:id/edit', element: <EditParticipantPage /> },
        { path: 'demo', element: <DemoPage /> }
      ]
    },
    {
      path: '/register/:id',
      element: <SimpleLayout />,
      children: [
        { path: '', element: <SingleRegistrationPage /> },
        { path: 'bulk', element: <BulkRegistrationPage /> },
        { path: 'thankyou', element: <ThankYouPage /> }
      ]
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/checkin',
      element: <CheckInLayout />,
      children: [
        { path: '', element: <CheckInPage /> },
        { path: ':code', element: <CheckInStatusPage /> }
      ]
    },
    { path: '/', element: <Navigate to="/dashboard" /> },
  ]);

  return routes;
}