import { Button, Divider, Grid, Typography, Radio as MuiRadio, FormControlLabel, Skeleton } from "@mui/material";
import { useForm } from "react-hook-form";
import { Input, File, Radio } from "../form-fields";

const REQUIRED_RULE = {
  required: {
    value: true,
    message: "This field is required"
  }
};

export default function EditRegistrationForm({ values, onSubmit, submitBtnRef, loading }) {

  const { handleSubmit, control } = useForm({
    mode: "onChange",
    defaultValues: {
      Multi_Event_Number_Of_Participants_Reserved: "",
    },
    values
  });

  const onSubmitCallback = (data) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitCallback)}>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center">
            Participants
          </Typography>
        </Grid>

        <Grid item xs={12}>

          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Number_Of_Participants_Reserved"
              rules={{ ...REQUIRED_RULE }}
              label="Number of Participants"
              InputProps={{
                readOnly: true,
              }}
            />
          )}

        </Grid>

        <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center">
            Permission Slip
          </Typography>
        </Grid>

        <Grid item xs={12} >
          <Button variant="outlined" fullWidth>
            Download Permission Slip Form
          </Button>
        </Grid>


        <Grid item xs={12}><Divider sx={{ my: 2 }} /></Grid>

        <Button
          sx={{ display: "none" }}
          fullWidth
          ref={submitBtnRef}
          variant="contained"
          color="primary"
          type="submit">
          Submit
        </Button>

      </Grid>
    </form>
  );
}