import { useState, useEffect, useCallback } from "react";
import { useConfig } from "../context/ConfigContext";
import { useAuth } from "../context/AuthContext";

export default function useFetch(endpoint, params = {}) {

  const { apiPath } = useConfig();
  const { authHeaders } = useAuth();

  const [data, setData] = useState(null);
  const [status, setStatus] = useState('not-fetched'); // not-fetched, fetching, fetched, fetch-error
  const [error, setError] = useState(null);

  let url = `${apiPath}${endpoint}`;
  if (Object.keys(params).length > 0) {
    url = `${url}?${new URLSearchParams(params).toString()}`;
  }

  useEffect(() => {
    let isCancelled = false;

    setStatus('fetching');
    fetch(url,
      {
        mode: 'cors',
        credentials: 'include',
        headers: authHeaders
      }
    ).then(res => {

      if (!res.ok) {
        throw Error('Failed to fetch (response not OK)');
      }
      return res.json();

    }).then(data => {

      if (isCancelled) return;
      setData(data);
      setStatus('fetched');
    }
    ).catch(err => {

      if (isCancelled) return;
      setError(err);
      setStatus('fetch-error');
    }
    );

    return () => {
      isCancelled = true;
    }

  }, [url, JSON.stringify(authHeaders)]);

  return { data, status, error };
}
