import useFetch from "./useFetch";

export default function useEvent(id) {
  const { data, status, error } = useFetch(`/events`, {
    Multi_Event_ID: id
  });

  const event = (status === 'fetched' && data.data.length > 0) ?
    data.data[0] : null;
    
  return { event, status, error };
}