import { Button } from "@mui/material";
import { useState } from "react";
import CopyIcon from '@mui/icons-material/ContentCopy';

export default function GetLinkButton({ buttonText, copyText }) {

  const [text, setText] = useState(buttonText);

  const handleClick = () => {
    navigator.clipboard.writeText(copyText);
    setText("Copied!");
    setTimeout(() => {
      setText(buttonText);
    }, 2000);
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      startIcon={<CopyIcon />}
    >
      {text}
    </Button>
  );
}