import { Button, Paper, TextField, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import "../theme/custom-less/login.less"
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: '#2c3852',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
}));

// ----------------------------------------------------------------------

export default function CheckInPage() {

  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setDirty(true);
    setCode(e.target.value);
  };

  const handleValidate = () => {

    if (!code) {
      setError("Please enter a confirmation code");
      return false;
    }

    setError("");
    return true;
  };

  const handleSubmit = () => {
    if (handleValidate()) {
      navigate("/checkin/" + code);
    }
  };

  useEffect(() => {
    if (dirty) handleValidate();
  }, [code]);

  return (
    <Paper elevation={3} sx={{ pt: 3, overflow: "hidden" }}>
      <Box sx={{ p: 3 }} >
        <Typography variant="h5" textAlign="center">
          Event Check In
        </Typography>
        <TextField
          label="Confirmation Code"
          fullWidth
          sx={{ mt: 4 }}
          error={!!error}
          helperText={error}
          value={code}
          onChange={handleChange}
        />
      </Box>
      <Button
        variant="contained"
        sx={{
          mt: 4,
          width: "100%",
          borderRadius: 0,
          height: 50,
          backgroundColor: "#758199",
        }}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Paper>

  );
}