import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Typography,
} from "@mui/material";
import ParticipantActions from "./ParticipantActions";
import OkIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Error";
import ErrorIcon from "@mui/icons-material/Cancel";
import { Box } from "@mui/system";

const statusToIcon = (status) => {
  switch (status) {
    case "OK":
      return <OkIcon sx={{ color: "success.main" }} />;
    case "NOT_OK":
      return <ErrorIcon sx={{ color: "error.main" }} />;
    case "NEEDS_ATTENTION":
      return <WarningIcon sx={{ color: "warning.main" }} />;
    default:
      return <OkIcon sx={{ color: "success.main" }} />;
  }
};

export default function ParticipantsTable({ participants, onParticipantEdit, onParticipantDelete }) {

  return (
    <TableContainer>

      <Table>

        <TableHead>

          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Permission Slip</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>

        </TableHead>

        <TableBody>

          {participants && participants.length === 0 && (
            <TableRow>
              <TableCell colSpan={5}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="body2" color="text.secondary">
                    No participants found.
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}

          {!participants && (
            [...Array(5)].map((_, index) => (
              <TableRow key={index}>
                {[...Array(5)].map((_, index) => (
                  <TableCell key={index} colSpan={1}>
                    <Skeleton />
                  </TableCell>
                ))}
              </TableRow>
            )
            ))
          }

          {participants && participants.map((participant) => (

            <TableRow key={participant.Multi_Event_Participant_ID}>
              <TableCell>{participant.Multi_Event_Participant_First_Name} {participant.Multi_Event_Participant_Last_Name}</TableCell>
              <TableCell>{participant.Multi_Event_Participant_Email_Address}</TableCell>
              <TableCell>{participant.Multi_Event_Participant_Phone_Number}</TableCell>
              <TableCell>
                {statusToIcon(participant.Multi_Event_Participant_Permission_Slip_Status)}
              </TableCell>

              <TableCell>

                <ParticipantActions
                  showView={false}
                  onEdit={() => { onParticipantEdit(participant.Multi_Event_Participant_ID) }}
                  onDelete={() => { onParticipantDelete(participant) }}
                />

              </TableCell>
            </TableRow>

          ))}

        </TableBody>

      </Table>

    </TableContainer>
  );
}