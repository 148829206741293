import { Box, Button, Container, Divider, Link, Paper, Skeleton, Stack, Typography } from '@mui/material'
import { RegistrationsAccordion, RegistrationsSearchInput, RegistrationTypeSelect, GetLinkButton, DeleteRegistrationModal } from '../sections/dashboard/event-detail';
import { fDateTime } from '../utils/formatTime';
import { fDollars } from '../utils/formatNumber';
import { useNavigate, useParams } from 'react-router-dom';
import { useEvent, useRegistrations } from '../hooks';
import { useState } from 'react';
import { useConfig } from '../context/ConfigContext';
import { useAuth } from '../context/AuthContext';

export default function EventDetailsPage() {

  const navigate = useNavigate();
  const { id } = useParams();
  const { apiPath } = useConfig();
  const { authHeaders, isAdmin } = useAuth();
  const {
    event,
    status: eventStatus,
    error: eventError
  } = useEvent(id);

  const [registrationFilters, setRegistrationFilters] = useState({
    Multi_Event_ID: id,
  });
  const [registrationType, setRegistrationType] = useState(null);
  const [deleteRegistration, setDeleteRegistration] = useState({
    showConfirm: false,
    registration: null
  });

  let {
    registrations,
    status: regStatus,
    error: regError
  } = useRegistrations(registrationFilters);


  const handleDeleteRegistration = () => {
    fetch(`${apiPath}/registrations/delete`, {
      method: "POST",
      headers: authHeaders,
      body: JSON.stringify(deleteRegistration.registration)
    }).then(res => {
      if (res.ok) return res.json();
      console.error("Error deleting registration: ", res);
    }).then(data => {
      setDeleteRegistration({
        showConfirm: false,
        registration: null
      });
      navigate(0);
    });
  };

  if (registrationType === 'bulk') {
    registrations = registrations.filter(r => r.Is_Bulk_Registration);
  } else if (registrationType === 'single') {
    registrations = registrations.filter(r => !r.Is_Bulk_Registration);
  }

  return (
    <Container>

      <Stack sx={{ py: 4 }} direction="row">

        <Typography variant="h4">
          Event Details
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" spacing={2}>

          <GetLinkButton
            buttonText="Get Link"
            copyText={`${window.location.origin}/events/${event?.Multi_Event_ID}`}
          />

          {isAdmin &&
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("./edit")}>
              Edit Event
            </Button>
          }

        </Stack>

      </Stack>

      <Paper sx={{ py: 4, px: 5, mb: 4 }}>

        <Typography variant="h5">
          {event ? event.Multi_Event_Title : <Skeleton width="100%" />}
        </Typography>

        <Typography variant="body1">
          {event ? `Event ID: ${event.Multi_Event_ID}` : <Skeleton width="100%" />}
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography variant="body1">
          {event ? `Date(s) and Time(s)` : <Skeleton width="100%" />}
        </Typography>

        <Typography variant="body2">
          {event ?
            `Start: ${fDateTime(event.Multi_Event_Start_Date)} at ${fDateTime(event.Multi_Event_Start_Date, 't')}` :
            <Skeleton width="100%" />
          }
        </Typography>

        <Typography variant="body2">
          {event ?
            `End: ${fDateTime(event.Multi_Event_End_Date)} at ${fDateTime(event.Multi_Event_End_Date, 't')}` :
            <Skeleton width="100%" />
          }
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography variant="body1">
          {event ? `Event Description` : <Skeleton width="100%" />}
        </Typography>

        <Typography variant="body2">
          {event ? event.Multi_Event_Description : <Skeleton width="100%" />}
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography variant="body1">
          {event ? `# of Participants` : <Skeleton width="100%" />}
        </Typography>

        <Typography variant="body2">
          {event ? event.Multi_Event_Number_Of_Participants : <Skeleton width="100%" />}
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography variant="body1">
          {event ? `Cost` : <Skeleton width="100%" />}
        </Typography>

        <Typography variant="body2">
          {event ? fDollars(event.Multi_Event_Cost) : <Skeleton width="100%" />}
        </Typography>

        <Divider sx={{ my: 2 }} />

      </Paper>

      <Paper sx={{ py: 2, px: 5 }}>

        <Stack direction="row" spacing={2} mb={2} alignItems={"center"}>

          <RegistrationTypeSelect
            onChange={(type) => {
              setRegistrationType(type);
            }}
          />
          <Box sx={{ flexGrow: 1 }} />
          <RegistrationsSearchInput
            onChange={(q) =>
              setRegistrationFilters(
                { ...registrationFilters, 'Keyword': q }
              )
            }
          />

        </Stack>

        <Box>
          <RegistrationsAccordion
            registrations={registrations}
            onRegistrationEdit={(id) => { navigate(`../registrations/${id}/edit`) }}
            onRegistrationDelete={(registration) => {
              setDeleteRegistration({
                showConfirm: true,
                registration: registration
              });
            }}
          />
        </Box>

      </Paper>

      <DeleteRegistrationModal
        registration={deleteRegistration.registration}
        open={deleteRegistration.showConfirm}
        onCancel={() => {
          setDeleteRegistration({
            showConfirm: false,
            registration: null
          });
        }}
        onConfirm={handleDeleteRegistration}
      />

    </Container>
  );
}