import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Skeleton,
  Box,
  Typography,
} from "@mui/material";
import { fDateTime } from "../../../utils/formatTime";
import OkIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Error";
import ErrorIcon from "@mui/icons-material/Cancel";
import EventActions from "./EventActions";

const statusToIcon = (status) => {
  switch (status) {
    case "OK":
      return <OkIcon sx={{ color: "success.main" }} />;
    case "NOT_OK":
      return <ErrorIcon sx={{ color: "error.main" }} />;
    case "NEEDS_ATTENTION":
      return <WarningIcon sx={{ color: "warning.main" }} />;
    default:
      return <OkIcon sx={{ color: "success.main" }} />;
  }
};

const DATE_FORMAT = "LLL d";
const eventDateString = (event) => {
  const startDate = fDateTime(event.Multi_Event_Start_Date, DATE_FORMAT);
  const endDate = fDateTime(event.Multi_Event_End_Date, DATE_FORMAT);
  return (startDate === endDate) ? startDate : `${startDate} - ${endDate}`;
}

export default function EventsTable({
  eventData,
  onEventRowClick,
  onEventView,
  onEventEdit,
  onEventDelete,
  isAdmin,
}) {

  return (
    <TableContainer>

      <Table>

        <TableHead>

          <TableRow>
            <TableCell>Event Name</TableCell>
            <TableCell>Event Date</TableCell>
            <TableCell>Payment</TableCell>
            <TableCell>Permission Slips</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>

        </TableHead>

        <TableBody>

          {eventData && eventData.length === 0 && (
            <TableRow>
              <TableCell colSpan={5}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="body2" color="text.secondary">
                    No events found.
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}

          {!eventData && [...Array(3)].map((_, index) => (
            <TableRow key={index}>
              {[...Array(5)].map((_, index) => (
                <TableCell key={index}>
                  <Skeleton />
                </TableCell>
              ))}
            </TableRow>
          ))}

          {eventData && eventData.map((event) => (

            <TableRow
              key={event.Multi_Event_ID}
              sx={{
                cursor: "pointer",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" }
              }}
              onClick={() => { onEventRowClick(event.Multi_Event_ID) }}
            >

              <TableCell>{event.Multi_Event_Title}</TableCell>
              <TableCell>{eventDateString(event)}</TableCell>
              <TableCell>{statusToIcon(event.Payment_Status)}</TableCell>
              <TableCell>{statusToIcon(event.Permission_Slips_Status)}</TableCell>
              <TableCell>

                <EventActions
                  onView={(e) => {
                    e.stopPropagation();
                    onEventView(event.Multi_Event_ID)
                  }}
                  onEdit={(e) => {
                    e.stopPropagation();
                    onEventEdit(event.Multi_Event_ID)
                  }}
                  onDelete={(e) => {
                    e.stopPropagation();
                    onEventDelete(event)
                  }}
                  showEdit={isAdmin}
                  showDelete={isAdmin}
                />

              </TableCell>

            </TableRow>

          ))}

        </TableBody>

      </Table>

    </TableContainer>
  );
}