import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import Logo from '../../components/logo/Logo';
import { Outlet } from 'react-router-dom';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: '#2c3852',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingBottom: theme.spacing(12),
}));

// ----------------------------------------------------------------------

export default function CheckInLayout() {

  return (
    <StyledRoot>
      <Main>
        <Container sx={{ mt: 10 }} maxWidth="sm">
          <Logo disabledLink sx={{ width: 150, mx: "auto", mb: 5 }} />
          <Outlet />
        </Container>
      </Main>
    </StyledRoot >
  );
}