import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Box, Avatar } from '@mui/material';
import Logo from '../../../components/logo';
import Navigation from './Navigation';
import AccountPopover from './AccountPopover';


export default function Header({ }) {
  return (
    <AppBar
      sx={{
        backgroundColor: 'primary.dark',
      }}
    >
      <Toolbar>
        <Logo />
        <Navigation />
        <Box sx={{ flexGrow: 1 }} />
        <AccountPopover />
      </Toolbar>
    </AppBar>
  )
}