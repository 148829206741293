import { Container, Stack, Button, Paper, Typography, Box } from "@mui/material";
import { BulkRegistrationForm } from "../components/registration-form";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useConfig } from "../context/ConfigContext";
import { useRef } from "react";

export default function BulkRegistrationPage() {

  const { id } = useParams();
  const { apiPath } = useConfig();
  const { authHeaders } = useAuth();
  const navigate = useNavigate();
  const submitBtnRef = useRef(null)

  const triggerSubmit = () => {
    submitBtnRef.current.click();
  };

  const handleSubmit = (data) => {

    data.Multi_Event_ID = id;
    fetch(`${apiPath}/registrations/create_bulk`, {
      method: "POST",
      headers: authHeaders,
      body: JSON.stringify(data)
    }).then(res => {
      if (res.ok) return res.json();
      console.error("Error creating registration: ", res);
    }).then(data => {
      console.log("Registration created: ", data);
      navigate("../thankyou");
    });
  }

  return (

    <Container>
      <Stack sx={{ py: 4 }} direction="row">

        <Typography variant="h4">
          Bulk Participant Event Registration
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        <Button 
          variant="contained" 
          color="primary"
          onClick={triggerSubmit}
        >
          Submit
        </Button>

      </Stack>

      <Paper sx={{ py: 2, px: 5 }}>

        <BulkRegistrationForm
          onSubmit={handleSubmit}
          submitBtnRef={submitBtnRef}
        />

      </Paper>

    </Container>
  );
}