import { TextField } from '@mui/material';
import { useController } from "react-hook-form";

export default function Input({ control, name, rules, ...other }) {

  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: rules,
  });

  return (
    <TextField
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      name={field.name}
      inputRef={field.ref}
      helperText={error?.message}
      error={invalid}
      {...other}
    />
  );
}