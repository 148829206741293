import { FormControl, Input, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import SearchIcon from '@mui/icons-material/SearchOutlined';

export default function RegistrationsSearchInput({ onChange }) {

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <FormControl variant="outlined">
      <OutlinedInput
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </FormControl>
  );
}