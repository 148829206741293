import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";


export default function EventDetails({ event }) {

  return (
    <Box>

      <Typography variant="h5" fontWeight="700">
        {event.Multi_Event_Title}
      </Typography>
      <Typography variant="body1" mt={1}>
        Event ID: {event.Multi_Event_ID}
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="body1">
        {event.Multi_Event_Description}
      </Typography>

    </Box>
  )
}