import { DatePicker } from "@mui/x-date-pickers";
import { useController } from "react-hook-form";


export default function Date({ control, name, rules, textFieldProps, ...other }) {

  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: rules,
  });


  return (
    <DatePicker
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      name={field.name}
      inputRef={field.ref}
      slotProps={{
        textField: {
          helperText: error?.message,
          error: invalid,
          ...textFieldProps
        },
      }}
      {...other}
    />
  );
}