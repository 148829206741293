import useFetch from "./useFetch";

export default function useRegistration(id) {
  const { data, status, error } = useFetch(`/registrations`, {
    Multi_Event_Registration_ID: id
  });

  const registration = (status === 'fetched' && data.data.length > 0) ?
    data.data[0] : null;

  return { registration, status, error };
}