import { Container, Stack, Button, Paper, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function ThankYouPage() {

  const { id } = useParams();
  const navigate = useNavigate();

  return (

    <Container>

      <Typography
        variant="h4"
        textAlign="center"
        mt={4}
      >
        Thank you for registering!
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate(`/events/${id}`)}
        sx={{
          mt: 4,
          mx: "auto",
          display: "block"
          
        }}
      >
        Back to Event
      </Button>

    </Container>
  );
}