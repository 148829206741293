import { Button, Grid, Skeleton } from "@mui/material";
import { useForm } from "react-hook-form";
import { Input, Date, Time } from "../form-fields";
import { DateTime } from "luxon";

const REQUIRED_RULE = {
  required: {
    value: true,
    message: "This field is required"
  }
};

const COST_RULE = {
  pattern: {
    value: /^-?\d+(\.\d+)?$/,
    message: "Not a valid price format."
  }
};

const NUMBER_RULE = {
  pattern: {
    value: /^\d+$/,
    message: "Not a valid number."
  }
};

export default function EventForm({ values, onSubmit, submitBtnRef, loading }) {

  if (values) {
    values.Multi_Event_Start_Date = DateTime.fromISO(values.Multi_Event_Start_Date);
    values.Multi_Event_End_Date = DateTime.fromISO(values.Multi_Event_End_Date);
    values.Multi_Event_Start_Time = values.Multi_Event_Start_Date
    values.Multi_Event_End_Time = values.Multi_Event_End_Date
  }

  const { handleSubmit, control } = useForm({
    mode: "onChange",
    defaultValues: {
      Multi_Event_Title: "",
      Multi_Event_Start_Date: null,
      Multi_Event_End_Date: null,
      Multi_Event_Start_Time: null,
      Multi_Event_End_Time: null,
      Multi_Event_Description: "",
      Multi_Event_Number_Of_Participants: "",
      Multi_Event_Cost: "",
    },
    values
  });

  const onSubmitCallback = (data) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitCallback)}>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Title"
              rules={{ ...REQUIRED_RULE }}
              label="Event Title"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          {loading ? <Skeleton height={40} /> : (
            <Date
              textFieldProps={{ fullWidth: true }}
              control={control}
              name="Multi_Event_Start_Date"
              rules={{ ...REQUIRED_RULE }}
              label="Start Date"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          {loading ? <Skeleton height={40} /> : (
            <Date
              textFieldProps={{ fullWidth: true }}
              control={control}
              name="Multi_Event_End_Date"
              rules={{ ...REQUIRED_RULE }}
              label="End Date"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          {loading ? <Skeleton height={40} /> : (
            <Time
              textFieldProps={{ fullWidth: true }}
              control={control}
              name="Multi_Event_Start_Time"
              rules={{ ...REQUIRED_RULE }}
              label="Start Time"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          {loading ? <Skeleton height={40} /> : (
            <Time
              textFieldProps={{ fullWidth: true }}
              control={control}
              name="Multi_Event_End_Time"
              rules={{ ...REQUIRED_RULE }}
              label="End Time"
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {loading ? <Skeleton height={40}  /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Description"
              rules={{ ...REQUIRED_RULE }}
              label="Description"
              multiline
              rows={4}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={9}>
          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Number_Of_Participants"
              rules={{ ...REQUIRED_RULE, ...NUMBER_RULE }}
              label="Number of Participants"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          {loading ? <Skeleton height={40} /> : (
            <Input
              fullWidth
              control={control}
              name="Multi_Event_Cost"
              rules={{ ...REQUIRED_RULE, ...COST_RULE }}
              label="Cost"
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Button
            sx={{ display: "none" }}
            ref={submitBtnRef}
            variant="contained"
            color="primary"
            type="submit">
            Save
          </Button>
        </Grid>

      </Grid>

    </form>
  );
}