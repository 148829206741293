import { createContext, useContext } from 'react';

const ConfigContext = createContext({
  apiPath: null,
  jwtAuthPath: null,
  tokenAuthPath: null,
  guidAuthPath: null
});

const scripts = document.querySelectorAll('script');
const thisScript = document.currentScript || scripts[scripts.length - 1];
const urlMatch = /^([a-zA-Z]+):\/\/([^\/]+)\/([^\/]+)\/js\/(.*?)\.js$/.exec(thisScript.src);
const legacyUrlMatch = /^([a-zA-Z]+):\/\/([^\/]+)\/js\/([^\/]+)\/(.*?)\.js$/.exec(thisScript.src);
let apiPath, jwtAuthPath, tokenAuthPath, guidAuthPath;

if (urlMatch) {
  apiPath = `${urlMatch[1]}://${urlMatch[2]}/${urlMatch[3]}/api`;
  jwtAuthPath = `${urlMatch[1]}://${urlMatch[2]}/${urlMatch[3]}/jwt_auth`;
  tokenAuthPath = `${urlMatch[1]}://${urlMatch[2]}/${urlMatch[3]}/token_auth`;
  guidAuthPath = `${urlMatch[1]}://${urlMatch[2]}/${urlMatch[3]}/guid_auth`;
  __webpack_public_path__ = `${urlMatch[1]}://${urlMatch[2]}/${urlMatch[3]}/app/`;
}
else if (legacyUrlMatch) {
  apiPath = `${legacyUrlMatch[1]}://${legacyUrlMatch[2]}/${legacyUrlMatch[3]}/api`;
  jwtAuthPath = `${legacyUrlMatch[1]}://${legacyUrlMatch[2]}/${legacyUrlMatch[3]}/jwt_auth`;
  tokenAuthPath = `${legacyUrlMatch[1]}://${legacyUrlMatch[2]}/${legacyUrlMatch[3]}/token_auth`;
  guidAuthPath = `${legacyUrlMatch[1]}://${legacyUrlMatch[2]}/${legacyUrlMatch[3]}/guid_auth`;
  __webpack_public_path__ = `${legacyUrlMatch[1]}://${legacyUrlMatch[2]}/${legacyUrlMatch[3]}/app/`;
}
else {
  apiPath = '/api';
  jwtAuthPath = '/jwt_auth';
  tokenAuthPath = '/token_auth';
  guidAuthPath = '/guid_auth';
  __webpack_public_path__ = `/app/`;
}

const config = {
  apiPath,
  jwtAuthPath,
  tokenAuthPath,
  guidAuthPath
};

export function getConfig() {
  return config;
}

export function useConfig() {
  return useContext(ConfigContext);
}

export function ConfigProvider({ children }) {
  return (
    <ConfigContext.Provider value={{ ...config }}>
      {children}
    </ConfigContext.Provider>
  );
}