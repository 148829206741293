import { Box, Button, Container, Paper, Stack, Typography } from '@mui/material'
import { ParticipantForm } from '../components/participant-form';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useConfig } from '../context/ConfigContext';
import { useRef } from 'react';

export default function CreateEventPage() {

  const { id } = useParams();
  const navigate = useNavigate();
  const { apiPath } = useConfig();
  const { authHeaders } = useAuth();
  const submitBtnRef = useRef(null);

  const triggerSubmit = () => {
    submitBtnRef.current.click();
  };

  const handleSubmit = (data) => {

    const formData = new FormData();

    formData.append("Multi_Event_Registration_ID", id);
    for (let key in data) {
      formData.append(key, data[key]);
    }

    fetch(`${apiPath}/participants/create`, {
      method: "POST",
      headers: authHeaders,
      body: formData
    }).then(res => {
      if (res.ok) return res.json();
      console.error("Error creating participant: ", res);
    }).then(data => {
      console.log("Participant created: ", data);
      navigate(-1);
    });
    
  }

  return (
    <Container>

      <Stack sx={{ py: 4 }} direction="row">

        <Typography variant="h4">
          Create Participant
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" spacing={1}>

          {/* <Button
            variant="contained"
            color="error"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button> */}

          <Button
            variant="contained"
            color="primary"
            onClick={triggerSubmit}
          >
            Save
          </Button>

        </Stack>

      </Stack>

      <Paper sx={{ py: 2, px: 5 }}>

        <ParticipantForm
          onSubmit={handleSubmit}
          submitBtnRef={submitBtnRef}
        />

      </Paper>

    </Container>
  );
}