import useFetch from "./useFetch";

export default function useParticipant(id) {
  const { data, status, error } = useFetch(`/participants`, {
    Multi_Event_Participant_ID: id
  });

  const participant = (status === 'fetched' && data.data.length > 0) ?
    data.data[0] : null;

  return { participant, status, error };
}