import { Button, Container, Paper, Skeleton, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { EventForm } from "../components/event-form";
import { useEvent } from "../hooks";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useAuth } from "../context/AuthContext";
import { useConfig } from "../context/ConfigContext";

export default function EditEventPage() {

  const { id } = useParams();
  const { event, status, error } = useEvent(id);
  const { apiPath } = useConfig();
  const { authHeaders } = useAuth();

  const navigate = useNavigate();
  const submitBtnRef = useRef(null);

  const triggerSubmit = () => {
    submitBtnRef.current.click();
  };

  const handleSave = (data) => {

    fetch(`${apiPath}/events/update`, {
      method: "POST",
      headers: authHeaders,
      body: JSON.stringify(data)
    }).then(res => {
      if (res.ok) return res.json();
      console.error("Error updating event: ", res);
    }).then(data => {
      navigate(-1);
    });

  }

  return (
    <Container>
      <Stack sx={{ py: 4 }} direction="row">

        <Typography variant="h4">
          Edit Event
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        <Button
          variant="contained"
          color="primary"
          onClick={triggerSubmit}>
          Save
        </Button>

      </Stack>

      <Paper sx={{ py: 2, px: 5 }}>

        <EventForm
          loading={!event}
          values={event}
          onSubmit={handleSave}
          submitBtnRef={submitBtnRef}
        />

      </Paper>

    </Container>
  );
}