import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar } from '@mui/material';
import Logo from '../../components/logo/Logo';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.customLight
}));

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function SimpleLayout() {

  return (
    <StyledRoot>
      <AppBar sx={{ backgroundColor: 'primary.dark' }}>
        <Toolbar>
          <Logo />
        </Toolbar>
      </AppBar>
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}