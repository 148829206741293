import { FormControl, FormHelperText, FormLabel, RadioGroup, TextField } from '@mui/material';
import { useController } from "react-hook-form";

export default function Radio({ control, name, rules, children, ...other }) {

  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: rules,
  });

  return (
    <FormControl error={invalid} fullWidth>
      <FormLabel>Payment Type</FormLabel>
      <RadioGroup value={field.value} onChange={field.onChange} name={field.name} row>
        {children}
      </RadioGroup>
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
}