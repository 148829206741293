import { Button } from "@mui/material";
import { useRef } from "react";
import { EventForm } from "../components/event-form";

export default function DemoPage() {

  const submitBtnRef = useRef(null);
  const triggerSubmit = () => {
    submitBtnRef.current.click();
  };
  const handleSubmit = (data) => {
    console.log(data);
  };


  return (
    <div>
      <h1>Demo Page</h1>
      <Button
        variant="contained"
        color="primary"
        onClick={triggerSubmit}>
        Save
      </Button>
      <EventForm
        onSubmit={handleSubmit}
        submitBtnRef={submitBtnRef}
      />
    </div>
  );
}