import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { getConfig } from "./ConfigContext";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {

  const { apiPath } = getConfig();

  const [isAdmin, setIsAdmin] = useState(false);
  const [state, setState] = useState({
    loggedIn: false,
    user: null,
    token: null,
    status: "uninitialized"
  });

  const logout = () => {

    if (window.altauth) {
      window.altauth.logout();
    } else {
      setState({
        loggedIn: false,
        user: null,
        token: null,
      });
    }

  };

  const altauthListener = (ctx, event) => {

    setState({
      loggedIn: ctx.loggedIn,
      user: ctx.user,
      token: ctx.token,
      status: ctx.status
    });

  }

  useEffect(() => {

    if (window.altauth) {
      window.altauth.addListener(altauthListener);
    }

    return () => {
      if (window.altauth) {
        window.altauth.removeListener(altauthListener);
      }
    }

  }, [window.altauth]);

  useEffect(() => {

    if (!state.token) return;

    fetch(`${apiPath}/users/me`, {
      headers: {
        'x-altauth-token': state.token
      }
    }).then(res => {
      if (!res.ok) {
        console.error('Failed to fetch user info.');
        return;
      }
      return res.json()
    }).then(res => {
      setIsAdmin(res.Is_Admin);
    })

  }, [state.token]);

  return (
    <AuthContext.Provider value={{
      ...state,
      authHeaders: {
        'x-altauth-token': state.token
      },
      initialized: state.status === "initialized",
      isAdmin,
      logout
    }}>
      {children}
    </AuthContext.Provider>
  );

}