import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";


export default function RegistrationTypeSelect({ onChange }) {

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <Box minWidth={200}>
      <FormControl fullWidth>
        <InputLabel>Registration Type</InputLabel>
        <Select
          label="Registration Type"
          onChange={handleChange}
          defaultValue="any"
        >
          <MenuItem value="any">Any</MenuItem>
          <MenuItem value="bulk">Bulk</MenuItem>
          <MenuItem value="single">Single</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}