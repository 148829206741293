import { Button, FormControl, FormHelperText, IconButton, Stack, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useController } from "react-hook-form";
import { useEffect, useRef, useState } from 'react';

export default function File({ control, name, rules, ...other }) {

  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: rules,
  });

  const [fileInputKey, setFileInputKey] = useState('');

  const clearFile = () => {
    const randKey = (Math.random() + 1).toString(36).substring(7);
    setFileInputKey(randKey);
    field.onChange(null);
  };

  const handleFileChange = (e) => {
    field.onChange(e.target.files[0]);
  };


  return (
    <Stack>

      <FormControl error={invalid}>

        <Button
          component="label"
          variant="contained">

          Upload File

          <input
            key={fileInputKey}
            type="file"
            hidden
            onChange={handleFileChange}
          />

        </Button>

        {!invalid && field.value &&

          <Stack direction="row" alignItems="center" mt={2} spacing={1}>

            <Typography variant="body2" fontWeight={700}>
              Uploaded: {field.value?.name}
            </Typography>

            <IconButton size="small" color="error" onClick={clearFile}>

              <ClearIcon sx={{ width: 20 }} />

            </IconButton>

          </Stack>

        }

        <FormHelperText>{error?.message}</FormHelperText>

      </FormControl>

    </Stack>
  );
}